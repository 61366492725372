@import "~@/styles/viewport.scss";
@import "~@/styles/mediaQuery.scss";

.container {
  @include mediaL {
    margin: 120px auto 160px auto;
  }
  @include mediaS {
    margin: vm(42) auto vm(64) auto;
  }
}

.imgBox {
  @include mediaL {
    width: 590px;
    height: 315px;
  }
  @include mediaS {
    width: vm(298);
    height: vm(159);
  }
}

.banner {
  @include mediaL {
    display: flex;
    justify-content: center;
  }
  @include mediaS {
  }
}

.infoBox {
  @include mediaL {
    padding: 79px 0 0 0;
  }
  @include mediaS {
    text-align: center;
  }
}

.title {
  font-weight: 500;
  @include mediaL {
    font-size: 40px;
    line-height: 49px;
  }
  @include mediaS {
    font-size: vm(24);
    line-height: vm(36);
  }
}

.info {
  font-weight: 400;
  @include mediaL {
    font-size: 18px;
    line-height: 24px;
    margin:8px auto  40px auto  ;
  }
  @include mediaS {
    font-size: vm(14);
    line-height: vm(24);
    margin:vm(8) auto  vm(40) auto  ;
  }
}

.link {
  font-weight: 400;
  @include mediaL {
    font-size: 18px;
    line-height: 24px;
  }
  @include mediaS {
    font-size: vm(14);
    line-height: vm(24);
  }
  a{
    text-decoration: none;
    color: #245BDB;
  }
}
